import { FC } from 'react';

import Subnav from 'components/layout/Subnav';

import projectLogos from 'images/loga_ue_fs.jpg';

const Investments: FC = () => (
  <div className="subpage-wrap">
    <div className="container">
      <div className="row">
        <div className="col-3-12">
          <Subnav url="/historia" />
        </div>

        <div className="col-9-12">
          <div className="about-txt">
            <div className="row">
              <div className="col-12-12">
                <div className="about-1">
                  <h4 className="underline">Projekty inwestycyjne dofinansowane z UE</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12-12">
                <h5 className="investments-subheader">
                  Mechanizm umożliwiający sygnalizowanie o potencjalnych nieprawidłowościach lub nadużyciach
                  finansowych:
                </h5>
                <ol className="investments-pois">
                  <li>specjalny adres e-mail: naduzycia.pois@mir.gov.pl</li>
                  <p>lub</p>
                  <li>
                    elektroniczny system zgłoszeń za pośrednictwem dedykowanej strony internetowej:{' '}
                    <a href="http://www.pois.gov.pl/nieprawidlowosci">www.pois.gov.pl/nieprawidlowosci</a>
                  </li>
                </ol>
                <div className="investments-divider"></div>
              </div>
            </div>
            <div className="row">
              <div className="col-12-12">
                <h5>
                  Projekt rozbudowy instalacji geotermalnej realizowany we współpracy z NFOŚiGW przy dofinansowaniu z
                  funduszy UE
                </h5>
                <div>
                  <img src={projectLogos} alt="" className="investments-logos" />
                </div>
                <div className="investments-info">
                  <p className="investments-info-title">Projekt</p>
                  <p>„Wykonanie odwiertów geotermalnych w Stargardzie”</p>
                  <p className="investments-info-title">Beneficjent:</p>
                  <p>G-Term Energy Sp. z o.o.</p>
                  <p className="investments-info-title">Opis projektu:</p>
                  <p>
                    Celem projektu jest rozbudowa instalacji geotermalnej w Stargardzie o dodatkowe 4 odwierty
                    geotermalne oraz nowy ciąg technologiczny, co pozwoli zwiększyć produkcję ekologicznego ciepła o
                    blisko 100% a tym samym znacząco zmniejszyć emisję zanieczyszczeń do atmosfery generowanych
                    aktualnie w związku z zaspokajaniem potrzeb grzewczych mieszkańców Stargardu.
                  </p>
                  <p className="investments-info-title">Wskaźniki produktu:</p>
                  <div>
                    <ul>
                      <li>Dodatkowa zdolność wytwarzania energii ze źródeł odnawialnych 12 MW</li>
                      <li>Dodatkowa zdolność wytwarzania energii cieplnej ze źródeł odnawialnych 12 MWt</li>
                      <li>Liczba przedsiębiorstw otrzymujących wsparcie 1 szt</li>
                      <li>Liczba jednostek wytwarzania energii cieplnej z OZE 1 szt</li>
                    </ul>
                  </div>
                  <p className="investments-info-title">Wskaźniki rezultatu:</p>
                  <div>
                    <ul>
                      <li>Szacowany roczny spadek emisji gazów cieplarnianych 23 390,75 ton równoważnika CO2</li>
                      <li>
                        Produkcja energii cieplnej z nowo wybudowanych/ nowych mocy wytwórczych instalacji
                        wykorzystujących OZE 62 222,22 MWht/rok
                      </li>
                    </ul>
                  </div>
                  <p className="investments-info-title">Całkowity koszt realizacji projektu:</p>
                  <p>95 940 024,00 PLN</p>
                  <p className="investments-info-title">Kwota wydatków kwalifikowanych:</p>
                  <p>72 100 000,00 PLN</p>
                  <p className="investments-info-title">Dofinansowanie projektu z UE:</p>
                  <p>40 113 600,00 PLN</p>
                  <p className="investments-info-last">Inwestycja zostanie zakończona w 2020 r.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Investments;
