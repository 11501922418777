import { FC } from 'react';
import { Link } from 'react-router-dom';

const NotFound: FC = () => (
  <div className="subpage-wrap">
    <div className="container">
      <div className="row">
        <div className="col-12-12">
          <div className="notfound-wrap">
            <div className="notfound-title">
              4<span>0</span>4
            </div>
            <h5>Strona o podanym adresie nie istnieje</h5>
            <div className="return">
              <Link to="/">
                <button className="return-btn">
                  <i className="fa fa-long-arrow-left" aria-hidden="true"></i>
                  Strona główna
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default NotFound;
