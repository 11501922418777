import React, { PropsWithChildren } from 'react';
import { PrismicClientHookState } from '@prismicio/react';

import Loader from 'components/loading/Loader';
import ApiErrorFallback from 'components/errors/ApiErrorFallback';

interface IProps {
  status: PrismicClientHookState;
  error?: Error | boolean;
}

const ApiLoader: React.FC<PropsWithChildren<IProps>> = ({ status, error, children }) => {
  if (error) {
    return <ApiErrorFallback />;
  }
  if (status === 'idle' || status === 'loading') {
    return <Loader />;
  }
  return <>{children}</>;
};

export default ApiLoader;
