import { FC } from 'react';
import { LinkProps, PrismicRichText, useSinglePrismicDocument } from '@prismicio/react';
import FileSaver from 'file-saver';

import ApiLoader from 'components/loading/ApiLoader';
import Subnav from 'components/layout/Subnav';
import { IPricingFilesData } from 'types/prismicTypes';

const Finances: FC = () => {
  const [document, { state, error }] = useSinglePrismicDocument<IPricingFilesData>('finanse');

  return (
    <ApiLoader status={state} error={error}>
      <div className="subpage-wrap">
        <div className="container">
          <div className="row">
            <div className="col-3-12">
              <Subnav url="/historia" />
            </div>
            <div className="col-9-12">
              <div className="about-txt">
                <div className="row">
                  <div className="col-12-12">
                    <div className="about-1">
                      <h4 className="underline">Dane finansowe</h4>
                      {document?.data.pliki?.map((node, idx) => (
                        <PrismicRichText
                          key={idx}
                          externalLinkComponent={({ href, children, ...props }: LinkProps) => {
                            return (
                              <a
                                href={href}
                                {...props}
                                onClick={(e) => {
                                  e.preventDefault();
                                  const foundHyperlink =
                                    'spans' in node && node.spans.find((item) => item.type === 'hyperlink');
                                  const fileName =
                                    foundHyperlink &&
                                    'data' in foundHyperlink &&
                                    'name' in foundHyperlink.data &&
                                    foundHyperlink.data.name;
                                  node.type !== 'image' &&
                                    node.type !== 'embed' &&
                                    FileSaver.saveAs(href, fileName || node.text);
                                }}
                              >
                                {children}
                              </a>
                            );
                          }}
                          field={[node]}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ApiLoader>
  );
};

export default Finances;
