import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { PrismicRichText, usePrismicDocumentByUID } from '@prismicio/react';

import ApiLoader from 'components/loading/ApiLoader';
import type { TTenderItemData } from 'types/prismicTypes';

const TendersOne: FC = () => {
  let { id } = useParams();
  const [document, { state, error }] = usePrismicDocumentByUID<TTenderItemData>('przetargi', id ?? '');

  return (
    <ApiLoader status={state} error={error}>
      <div className="subpage-wrap tenders">
        <div className="container">
          <div className="row">
            <div className="col-9-12">
              <h3 className="tenders-header underline">Ogłoszenia o przetargach</h3>
            </div>
            <div className="col-3-12">
              <div className="tenders-return-top">
                <Link to={'/przetargi'}>
                  <button className="return-btn">
                    <i className="fa fa-arrow-left" aria-hidden="true"></i>
                    Powrót do listy
                  </button>
                </Link>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12-12">
              <div className="tenders-wrap">
                {
                  <div>
                    <div className="tenders-one-title">
                      <h3>{document?.data.tytul[0]?.text}</h3>
                      <h3>{document?.data.podtytul[0]?.text}</h3>
                    </div>
                    <div className="tenders-one-text">{<PrismicRichText field={document?.data.tekst} />}</div>
                    <div className="tenders-one-docs">{<PrismicRichText field={document?.data.dokumentacja} />}</div>
                    <div className="tenders-one-links">
                      <h5>{<PrismicRichText field={document?.data.zalaczniki} />}</h5>
                    </div>
                    <div className="tenders-one-date">
                      Ostatnia aktualizacja: {document?.last_publication_date.slice(0, 10)}
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12-12">
              <div className="tenders-return-bottom">
                <Link to={'/przetargi'}>
                  <button className="return-btn">
                    <i className="fa fa-arrow-left" aria-hidden="true"></i>
                    Powrót do listy
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ApiLoader>
  );
};

export default TendersOne;
