import { FC } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter } from 'react-router-dom';
import { PrismicProvider } from '@prismicio/react';

import { client } from 'utils/prismic';
import ScrollToTop from 'utils/ScrollToTop';
import ErrorBoundaryFallback from 'components/errors/ErrorBoundaryFallback';
import Header from 'components/layout/Header';
import Footer from 'components/layout/Footer';
import RoutesWrapper from 'components/routing/RoutesWrapper';

const App: FC = () => (
  <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onReset={() => window.location.reload()}>
    <PrismicProvider client={client}>
      <BrowserRouter>
        <ScrollToTop />
        <Header />
        <div className="main-display">
          <RoutesWrapper />
        </div>
        <Footer />
      </BrowserRouter>
    </PrismicProvider>
  </ErrorBoundary>
);

export default App;
