const menuItems = [
  {
    name: 'O firmie',
    url: '/historia',
    subItems: [
      {
        name: 'Historia firmy',
        url: '/historia'
      },
      {
        name: 'Instalacja w Stargardzie',
        url: '/instalacja-w-stargardzie'
      },
      {
        name: 'Realizowane inwestycje',
        url: '/realizowane-inwestycje'
      },
      {
        name: 'Geotermia w Polsce',
        url: '/geotermia-w-polsce'
      },
      {
        name: 'Dane finansowe',
        url: '/dane-finansowe'
      }
    ]
  },
  {
    name: 'Aktualności',
    url: '/aktualnosci'
  },
  {
    name: 'Przetargi',
    url: '/przetargi'
  },
  {
    name: 'Taryfa',
    url: '/taryfa'
  }
];

export default menuItems;
