import { FC } from 'react';
import { Link } from 'react-router-dom';

import pagesImg1 from 'images/home-pages-1.jpg';
import pagesImg2 from 'images/home-pages-2.jpg';
import pagesImg3 from 'images/home-pages-3.jpg';
import logoPSG from 'images/partners-PSG.png';
import logoUE_IS from 'images/logo_UE_ Infrastruktura_i_Srodowisko.jpg';
import logoUE_FS from 'images/logo_UE_Fundusz_Spojnosci.jpg';

const Home: FC = () => (
  <div className="home-wrap">
    {/* ---- Hero Section ---- */}
    <section className="home-hero" id="home">
      <div className="container">
        <div className="row">
          <h1 className="home-hero-title">
            <span>Czyste </span>
            <span>ciepło </span>
            <span>z ziemi</span>
          </h1>
        </div>
      </div>
    </section>
    {/* ---- UE program Section ---- */}
    <section className="home-ue">
      <div className="container">
        <div className="row">
          <div className="col-1-12"></div>
          <div className="col-10-12 home-ue-md-p0">
            <div className="home-ue-wrap">
              <h4 className="home-ue-header">Rozbudowa Geotermii</h4>
              <div className="row">
                <div className="col-4-12 flex-v-center">
                  <div className="home-ue-images">
                    <img src={logoUE_IS} alt="Logo Fundusze Europejskie Infrastruktura i Środowisko"></img>
                    <img src={logoUE_FS} alt="Logo Fundusz Spójności"></img>
                  </div>
                </div>
                <div className="col-8-12 flex-v-center">
                  <div className="home-ue-content">
                    We współpracy z NFOŚiGW oraz dzięki uzyskanemu dofinansowaniu z funduszy unijnych zrealizowaliśmy
                    projekt rozbudowy, który pozwoli blisko dwukrotnie zwiększyć produkcję ekologicznego ciepła w naszej
                    instalacji.
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12-12">
                  <div className="home-ue-button">
                    <Link to="/realizowane-inwestycje">
                      <button className="primary-btn">O inwestycji</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-1-12"></div>
        </div>
      </div>
    </section>
    {/* ---- About Section ---- */}
    <section className="home-about">
      <div className="container">
        <div className="row home-about-align">
          <div className="col-6-12">
            <div className="home-about-1">
              <h3 className="home-about-title underline">O nas</h3>
              <h5 className="home-about-txt">
                Geotermia Stargard jest drugą w Polsce pod względem produkcji ciepła instalacją geotermalną.
                Dostarczając ciepłą wodę użytkową dla mieszkańców miasta, pokrywa ponad 30% zapotrzebowania sieci
                miejskiej w Stargardzie. Od 2011 roku właścicielem geotermii jest spółka G&nbsp;-&nbsp;TERM ENERGY
                Sp.&nbsp;z&nbsp;o.&nbsp;o., która wykonując szereg inwestycji i prac naprawczych ponownie uruchomiła i
                kontynuuje rozbudowę instalacji w Stargardzie.
              </h5>
            </div>
          </div>
          <div className="col-6-12 home-about-right">
            <div className="home-about-2">
              <div className="row">
                <div className="home-about-numbers">
                  <p>Temperatura wody</p>
                  <h3>84 °C</h3>
                </div>
              </div>
              <div className="row">
                <div className="home-about-numbers">
                  <p>Roczna produkcja</p>
                  <h3>ok.200 tys GJ</h3>
                </div>
              </div>
              <div className="row">
                <div className="home-about-numbers">
                  <p>Moc układu</p>
                  <h3>12 MW</h3>
                </div>
              </div>
              <div className="row">
                <div className="home-about-numbers">
                  <p>Rok założenia</p>
                  <h3>2005</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* ---- Subpage Links Section ---- */}
    <section className="home-pages">
      <div className="container">
        <div className="row">
          <div className="col-12-12">
            <h3 className="home-pages-header underline">Czytaj więcej</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-4-12">
            <Link to="/historia">
              <div className="home-pages-box">
                <img src={pagesImg1} alt="G-Term Energy" />
                <h5 className="home-pages-title">O firmie</h5>
              </div>
            </Link>
          </div>
          <div className="col-4-12">
            <Link to="/instalacja-w-stargardzie">
              <div className="home-pages-box">
                <img src={pagesImg2} alt="G-Term Energy" />
                <h5 className="home-pages-title">Instalacja w Stargardzie</h5>
              </div>
            </Link>
          </div>
          <div className="col-4-12">
            <Link to="/geotermia-w-polsce">
              <div className="home-pages-box">
                <img src={pagesImg3} alt="G-Term Energy" />
                <h5 className="home-pages-title">Geotermia w Polsce</h5>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
    {/* ---- Directors and Partners Section ---- */}
    <section className="home-more">
      <div className="container">
        <div className="row">
          <div className="col-6-12">
            <div className="home-more-people">
              <h4>G-TERM ENERGY Sp.&nbsp;z&nbsp;o.&nbsp;o.</h4>
              <div>
                <p className="home-more-people-name">Prezes Zarządu:</p>
                <p>Arkadiusz Biedulski</p>
                <p className="home-more-people-name">Dyrektor Zakładu:</p>
                <p>dr inż. Władysław Zabłocki</p>
                <p>tel. +48 533 41 44 44</p>
              </div>
            </div>
          </div>
          <div className="col-6-12">
            <div className="home-more-partners">
              <h4 className="home-more-partners-header">Współpracujemy z:</h4>
              <div className="home-more-partners-box">
                <a href="http://www.energia-geotermalna.org.pl/">
                  <img src={logoPSG} alt="Logo Polskie Stowarzyszenie Geotermiczne" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
);

export default Home;
