import { FC } from 'react';

const ApiErrorFallback: FC = () => (
  <div className="container">
    <div className="api-error-fallback">
      <h1>Przepraszamy</h1>
      <p>Wystąpił błąd podczas pobierania danych. Odśwież stronę lub spróbuj później.</p>
    </div>
  </div>
);

export default ApiErrorFallback;
