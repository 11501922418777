import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useAllPrismicDocumentsByType } from '@prismicio/react';

import ApiLoader from 'components/loading/ApiLoader';
import type { TTenderItemData } from 'types/prismicTypes';

const Tenders: FC = () => {
  const [tenders, { state, error }] = useAllPrismicDocumentsByType<TTenderItemData>('przetargi', {
    orderings: {
      field: 'document.first_publication_date',
      direction: 'desc'
    }
  });

  return (
    <ApiLoader status={state} error={error}>
      <div className="subpage-wrap tenders">
        <div className="container">
          <div className="row">
            <div className="col-12-12">
              <h3 className="tenders-header underline">Ogłoszenia o przetargach</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-12-12">
              <div className="tenders-wrap">
                {tenders?.map((el) => (
                  <div className="tenders-list" key={el.uid}>
                    <h5 className="tenders-title">
                      <Link to={`/przetargi/${el.uid}`}>{el.data.tytul[0]?.text}</Link>
                    </h5>
                    <div className="tenders-date">Ostatnia aktualizacja: {el.last_publication_date.slice(0, 10)}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ApiLoader>
  );
};

export default Tenders;
