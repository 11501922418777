import { FC } from 'react';

import Subnav from 'components/layout/Subnav';

const Stargard: FC = () => (
  <div className="subpage-wrap">
    <div className="container">
      <div className="row">
        <div className="col-3-12">
          <Subnav url="/historia" />
        </div>

        <div className="col-9-12">
          <div className="about-txt">
            <div className="row">
              <div className="col-12-12">
                <div className="about-1">
                  <h4 className="underline">Instalacja w Stargardzie</h4>

                  <p>
                    Aktualnie w instalacji Geotermii Stargard eksploatowane są trzy odwierty geotermalne: jeden odwiert
                    wydobywczy (GT-2) oraz dwa odwierty zatłaczające (GT-1 oraz GT-3). Wydobywana woda termalna
                    (solanka) z utworów Jury Dolnej ma temperaturę ok. 84°C, a jej zasolenie przekracza 140 g/dm3. Po
                    oddaniu energii w wymienniku ciepła schłodzona solanka (o temperaturze w zakresie 40 do 55 °C)
                    trafia do otworów zatłaczających. Dzięki zamkniętemu obiegowi wody termalnej, w złożu zachowywana
                    jest równowaga hydrogeologiczna co umożliwia jego eksploatację praktycznie bez ograniczenia
                    czasowego.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6-12">
                <p className="stargard-par2">
                  W okresie grzewczym Geotermia dostarcza ciepło do odbiorców wspólnie z PEC Stargard. Po planowanej
                  rozbudowie, zwiększającej moc układu geotermalnego do blisko 30 MW, wydłuży się samodzielny okres
                  pracy ciepłowni geotermalnej również na część sezonu grzewczego.
                </p>
                <p>
                  Docelowo Geotermia Stargard będzie pracować w oparciu o siedem otworów geotermalnych (3 wydobywcze i 4
                  zatłaczające) i wspomagana będzie pompą ciepła o mocy 14 MW.
                </p>
                <p>
                  Po tej rozbudowie system ciepłowniczy Stargardu spełniać będzie wymogi{' '}
                  <span className="italic">„efektywnego systemu ciepłowniczego”</span>. Powyższe rozwiązanie jest
                  pierwszym na taką skalę w kraju systemowym rozwiązaniem, polegającym na współpracy miejskiej sieci
                  ciepłowniczej z posiadającą własne źródło ciepłownią geotermalną.
                </p>
              </div>
              <div className="col-6-12">
                <div className="about-img stargard-img"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Stargard;
