import { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import menuItems from 'components/layout/menuItems';

interface IProps {
  url: string;
}

const Subnav: FC<PropsWithChildren<IProps>> = ({ url }) => {
  const menuItem = menuItems.find((item) => item.url === url);

  if (!menuItem) {
    return null;
  }

  if (!menuItem.subItems?.length) {
    return (
      <div className="subpage-nav">
        <ul>
          <li>
            <i className="fa fa-angle-right" aria-hidden="true"></i>
            <Link to={menuItem.url}>{menuItem.name}</Link>
          </li>
        </ul>
      </div>
    );
  }

  return (
    <div className="subpage-nav">
      <ul>
        {menuItem.subItems.map((el, i) => {
          return (
            <li key={i}>
              <i className="fa fa-angle-right" aria-hidden="true"></i>
              <Link to={el.url}>{el.name}</Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Subnav;
