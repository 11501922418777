import React from 'react';

import Subnav from 'components/layout/Subnav';

const Poland = () => (
  <div className="subpage-wrap">
    <div className="container">
      <div className="row">
        <div className="col-3-12">
          <Subnav url="/historia" />
        </div>
        <div className="col-9-12">
          <div className="about-txt">
            <div className="row">
              <div className="col-12-12">
                <div className="about-1">
                  <h4 className="underline">Geotermia w Polsce</h4>
                  <p className="poland-par1">
                    Warunki związane z inwestowaniem w energię geotermalną w Polsce należy określić jako relatywnie
                    dobre, ponad 80% powierzchni kraju jest pokryte przez 3 prowincje geotermalne: Karpacką, Zapadlisko
                    Przedkarpackie oraz Niż Polski. Temperatura wody dla tych obszarów wynosi od 30 do 130 °C (a
                    lokalnie nawet 200 °C), a głębokość występowania w skałach osadowych od 1 do 10 km. Możliwości
                    wykorzystania wód geotermalnych dotyczą 40% obszaru kraju, przy czym szacuje się, że wydobycie jest
                    opłacalne, gdy do głębokości 2 km temperatura osiąga min. 50-60°C a wydajność źródła wynosi min. 100
                    m3/h.
                  </p>
                </div>
              </div>
            </div>
            <div className="row poland-flex-row">
              <div className="col-6-12 poland-flex-item">
                <p className="poland-par2">
                  Szacuje się, że koszt wykonania kompletnej instalacji geotermalnej o mocy około 10 MW, wykorzystującej
                  dwa odwierty i produkującej ok. 200 tys. GJ ciepła rocznie wynosi ok. 40-45 mln zł co stanowi bardzo
                  konkurencyjne rozwiązanie w porównaniu do innych źródeł OZE.
                </p>
                <p>
                  Dodatkowym aspektem przemawiającym za inwestowaniem w rozwiązania geotermalne są alternatywne
                  możliwości wykorzystania nadwyżek ciepła / ciepła odpadowego zatłaczanego do Ziemi - do ogrzewania np.
                  szklarni czy zasilania parków wodnych. Takie rozwiązania z powodzeniem stosowane są w Termach Bania w
                  Białce Tatrzańskiej, Uniejowie czy Termach Maltańskich w Poznaniu.
                </p>
              </div>
              <div className="col-6-12 poland-flex-item">
                <div className="about-img poland-img"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Poland;
