import { ComponentType } from 'react';
import { FallbackProps } from 'react-error-boundary';

const ErrorBoundaryFallback: ComponentType<FallbackProps> = ({ resetErrorBoundary }) => (
  <div className="container">
    <div className="error-boundary-fallback">
      <h1>Coś poszło nie tak</h1>
      <p>Przepraszamy, wystąpił nieznany błąd. Odśwież stronę lub spróbuj ponownie później.</p>
      <button className="return-btn" onClick={resetErrorBoundary}>
        Odśwież stronę
      </button>
    </div>
  </div>
);

export default ErrorBoundaryFallback;
