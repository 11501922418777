import { FC } from 'react';
import { PrismicRichText, useAllPrismicDocumentsByType } from '@prismicio/react';

import ApiLoader from 'components/loading/ApiLoader';
import type { TNewsItemData } from 'types/prismicTypes';

const News: FC = () => {
  const [news, { state, error }] = useAllPrismicDocumentsByType<TNewsItemData>('aktualnosci', {
    orderings: {
      field: 'aktualnosci.data_ogloszenia',
      direction: 'desc'
    }
  });

  return (
    <ApiLoader status={state} error={error}>
      <div className="subpage-wrap">
        <div className="container">
          <div className="row">
            <div className="col-12-12">
              <h3 className="news-header underline">Aktualności</h3>
            </div>
          </div>
          {news?.map((el) => (
            <div className="row" key={el.id}>
              <div className="col-12-12">
                <div className="news-wrap">
                  <h4 className="news-title">{el.data.tytul[0]?.text}</h4>
                  <div className="news-date">{el.data.data_ogloszenia}</div>
                  {el.data.tekst && <PrismicRichText field={el.data.tekst} />}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </ApiLoader>
  );
};

export default News;
