import { FC } from 'react';

import Subnav from 'components/layout/Subnav';

const History: FC = () => (
  <div className="subpage-wrap">
    <div className="container">
      <div className="row">
        <div className="col-3-12">
          <Subnav url="/historia" />
        </div>
        <div className="col-9-12">
          <div className="about-txt">
            <div className="row">
              <div className="col-6-12">
                <div className="about-1">
                  <h4 className="underline">Historia</h4>
                  <p className="history-par1">
                    Pierwsze uruchomienie i wydobycie gorących wód termalnych w Stargardzie miało miejsce w 2005 roku.
                    Ciepło wydobywane w Geotermii trafiało do sieci miejskiej zapewniając ciepłą wodę użytkową. Wspólnie
                    z Przedsiębiorstwem Energetyki Cieplnej Stargard (ciepłownią konwencjonalną) Geotermia dostarczała
                    ciepło grzewcze dla mieszkańców Stargardu.
                  </p>
                </div>
              </div>
              <div className="col-6-12">
                <div className="about-img history-img"></div>
              </div>
            </div>
            <div className="row">
              <div className="col-12-12">
                <p>
                  W 2011 roku Geotermia została przejęta przez spółkę G-TERM ENERGY Sp. z o.o., która wykonała szereg
                  kosztownych inwestycji, prac naprawczych i uruchomiła ponownie instalację geotermalną. W roku 2016
                  wykonano kolejny otwór chłonny GT-3, co wpłynęło na znaczące zwiększenie mocy cieplnej Geotermii.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12-12">
                <p>
                  Aktualnie zakład dysponuje mocą na poziomie 11 MW. Geotermia Stargard jest drugą co do wielkości w
                  Polsce instalacją geotermalną. Roczna produkcja ciepła wynosi ok. 200 tys. GJ i pokrywa ponad 30%
                  zapotrzebowania sieci miejskiej w Stargardzie.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default History;
