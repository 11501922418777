import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import mainLogo from 'images/G-Term_logo_black.png';
import logoUE_IS from 'images/logo_UE_ Infrastruktura_i_Srodowisko.jpg';
import logoUE_FS from 'images/logo_UE_Fundusz_Spojnosci.jpg';

import menuItems from 'components/layout/menuItems';

const Header: FC = () => {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked((isClicked) => !isClicked);
  };

  const menuListLarge = menuItems.map((el, i) => {
    if (!el.subItems?.length) {
      return (
        <li className="page-nav-lg-list-item" key={i}>
          <Link to={el.url}>{el.name}</Link>
        </li>
      );
    } else {
      const subListLarge = el.subItems?.map((elem) => {
        return (
          <li className="page-nav-lg-sublist-item" key={elem.url}>
            <Link to={elem.url}>{elem.name}</Link>
          </li>
        );
      });

      return (
        <li className="page-nav-lg-list-item" key={i}>
          <Link to={el.url}>{el.name}</Link>
          <ul className="page-nav-lg-sublist">{subListLarge}</ul>
        </li>
      );
    }
  });

  const menuListMobile = menuItems.map((el, i) => {
    if (!el.subItems?.length) {
      return (
        <li className="page-nav-mobile-list-item" key={i}>
          <Link to={el.url} onClick={handleClick}>
            {el.name}
          </Link>
        </li>
      );
    } else {
      const subListMobile = el.subItems?.map((elem) => {
        return (
          <li className="page-nav-mobile-sublist-item" key={elem.url}>
            <Link to={elem.url} onClick={handleClick}>
              {elem.name}
            </Link>
          </li>
        );
      });

      return (
        <li className="page-nav-mobile-list-item" key={i}>
          <Link to={el.url} onClick={handleClick}>
            {el.name}
          </Link>
          <ul className="page-nav-mobile-sublist">{subListMobile}</ul>
        </li>
      );
    }
  });

  return (
    <header className="page-header">
      <div className="page-nav-holder">
        <div className="container">
          <div className="row">
            <div className="page-nav-col-logos">
              <div className="page-logo">
                <Link to="/">
                  <img src={mainLogo} alt="G-Term Energy" />
                </Link>
              </div>
              <div className="page-nav-ue-logo-1">
                <img src={logoUE_IS} alt="Logo Fundusze Europejskie Infrastruktura i Środowisko"></img>
              </div>
              <div className="page-nav-ue-logo-2">
                <img src={logoUE_FS} alt="Logo Fundusz Spójności"></img>
              </div>
            </div>

            <div className="page-nav-col-links">
              <div className="page-nav-wrap-lg">
                <div className="page-nav-contact icon-text">
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                  <a href="mailto:biuro@gterm.pl">biuro@gterm.pl</a>
                  <i className="fa fa-phone" aria-hidden="true"></i>
                  <p>tel +48 52 318 86 77</p>
                  <i className="fa fa-fax" aria-hidden="true"></i>
                  <p>fax +48 52 318 85 14</p>
                </div>
                <nav className="page-nav-lg">
                  <ul className="page-nav-lg-list">
                    <li className="page-nav-lg-list-item">
                      <HashLink to="/#home">Home</HashLink>
                    </li>
                    {menuListLarge}
                    <li className="page-nav-lg-list-item">
                      <HashLink to="#kontakt">Kontakt</HashLink>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>

            <button className="page-nav-hamburger" onClick={handleClick}>
              <span></span>
              <span></span>
              <span></span>
            </button>

            <div className={isClicked ? 'page-nav-wrap-mobile mobile-nav-show' : 'page-nav-wrap-mobile'}>
              <button className="page-nav-mobile-close" onClick={handleClick}>
                <i className="fa fa-window-close" aria-hidden="true"></i>
              </button>
              <nav className="page-nav-mobile">
                <ul className="page-nav-mobile-list">
                  <li className="page-nav-mobile-list-item">
                    <HashLink to="/#home" onClick={handleClick}>
                      Home
                    </HashLink>
                  </li>
                  {menuListMobile}
                  <li className="page-nav-mobile-list-item">
                    <HashLink to="#kontakt" onClick={handleClick}>
                      Kontakt
                    </HashLink>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
