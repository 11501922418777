import { FC } from 'react';
import { LinkProps, PrismicRichText, useSinglePrismicDocument } from '@prismicio/react';
import FileSaver from 'file-saver';

import ApiLoader from 'components/loading/ApiLoader';
import { IPricingFilesData } from 'types/prismicTypes';

const Pricing: FC = () => {
  const [document, { state, error }] = useSinglePrismicDocument<IPricingFilesData>('taryfa');

  return (
    <ApiLoader status={state} error={error}>
      <div className="subpage-wrap">
        <div className="container">
          <div className="row">
            <div className="col-12-12">
              <h3 className="news-header underline">Taryfa</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-12-12">
              <div className="">
                {document?.data.pliki?.map((node, idx) => (
                  <PrismicRichText
                    key={idx}
                    externalLinkComponent={({ href, children, ...props }: LinkProps) => {
                      return (
                        <a
                          href={href}
                          {...props}
                          onClick={(e) => {
                            e.preventDefault();
                            const foundHyperlink =
                              'spans' in node && node.spans.find((item) => item.type === 'hyperlink');
                            const fileName =
                              foundHyperlink &&
                              'data' in foundHyperlink &&
                              'name' in foundHyperlink.data &&
                              foundHyperlink.data.name;
                            node.type !== 'image' &&
                              node.type !== 'embed' &&
                              FileSaver.saveAs(href, fileName || node.text);
                          }}
                        >
                          {children}
                        </a>
                      );
                    }}
                    field={[node]}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ApiLoader>
  );
};

export default Pricing;
