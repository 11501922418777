import { FC } from 'react';
import { Link } from 'react-router-dom';

import footerLogo from 'images/G-Term_logo_grey.png';

const Footer: FC = () => (
  <footer className="page-footer">
    <div className="container">
      <div className="row">
        <div className="col-4-12">
          <div className="page-footer-c1">
            <div className="page-footer-logo">
              <Link to="/">
                <img src={footerLogo} alt="G-Term Energy" />
              </Link>
            </div>
            <div className="page-footer-contact">
              <div className="icon-text">
                <i className="fa fa-phone" aria-hidden="true"></i>
                <p>tel +48 52 318 86 77</p>
              </div>
              <div className="icon-text">
                <i className="fa fa-fax" aria-hidden="true"></i>
                <p>fax +48 52 318 85 14</p>
              </div>
              <div className="icon-text">
                <i className="fa fa-envelope" aria-hidden="true"></i>
                <a href="mailto:biuro@gterm.pl">biuro@gterm.pl</a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-4-12">
          <div className="page-footer-c2">
            <h4 className="page-footer-up footer-header">Kontakt</h4>
            <h5 className="page-footer-low">G-TERM ENERGY Sp. z o.o</h5>
            <p>Wymysłowice 1</p>
            <p>88-320 Strzelno</p>
          </div>
        </div>
        <div className="col-4-12">
          <div className="page-footer-c3">
            <h4 className="page-footer-up footer-header">Zakład Górniczy</h4>
            <h5 className="page-footer-up">Geotermia Stargard</h5>
            <p>ul. Cieplna 5A</p>
            <p>73-110 Stargard</p>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12-12">
          <div className="footer-copyright" id="kontakt">
            &copy; 2022 G-Term Energy Sp. z o. o.
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
