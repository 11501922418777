import { FC } from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from 'components/pages/Home';
import History from 'components/pages/History';
// import Group from 'components/pages/Group'; // ta strona nigdy nie weszła
import Stargard from 'components/pages/Stargard';
import Poland from 'components/pages/Poland';
import Investments from 'components/pages/Investments';
import News from 'components/pages/News';
import Tenders from 'components/pages/Tenders';
import TendersOne from 'components/pages/TendersOne';
import Pricing from 'components/pages/Pricing';
import Finances from 'components/pages/Finances';
import NotFound from 'components/pages/NotFound';

// const History = AsyncLoader(() => import('components/pages/History'));
// // const Group = AsyncLoader(() => import('components/pages/Group'));  // ta strona nigdy nie weszła
// const Stargard = AsyncLoader(() => import('components/pages/Stargard'));
// const Poland = AsyncLoader(() => import('components/pages/Poland'));
// const Investments = AsyncLoader(() => import('components/pages/Investments'));
// const News = AsyncLoader(() => import('components/pages/News'));
// // const Tenders = AsyncLoader(() => import('components/pages/Tenders'));
// // const TendersOne = AsyncLoader(() => import('components/pages/TendersOne'));
// const NotFound = AsyncLoader(() => import('components/pages/NotFound'));

const RoutesWrapper: FC = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/historia" element={<History />} />
    <Route path="/instalacja-w-stargardzie" element={<Stargard />} />
    <Route path="/geotermia-w-polsce" element={<Poland />} />
    <Route path="/realizowane-inwestycje" element={<Investments />} />
    <Route path="/aktualnosci" element={<News />} />
    <Route path="/przetargi" element={<Tenders />} />
    <Route path="/przetargi/:id" element={<TendersOne />} />
    <Route path="/taryfa" element={<Pricing />} />
    <Route path="/dane-finansowe" element={<Finances />} />
    <Route element={<NotFound />} />
  </Routes>
);

export default RoutesWrapper;
